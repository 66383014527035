import { URLService, ModalService, RequestService } from "./services";
import { IndexedDBService as DatabaseService } from "./services";
import { default as FormManager } from "./formmanager";
import { default as validators } from './validators';

const setInitialValues = (data, self) => {
	document.getElementById(self.fields.ciclo).value = data.CicloActual.Ciclo;
	document.getElementById(self.fields.hidden.idCiclo).value = data.CicloActual.IdCiclo;
	document.getElementById(self.fields.folio).value = data.Folio;
	if (self instanceof BulkModalManager) {
		const seccionField = document.getElementById(self.fields.seccionRiego);
		seccionField.value = data.seccionRiego;
		seccionField.disabled = true;
	}
	document.getElementById(self.fields.fechaCaptura).value = new Date(new Date().setDate(new Date().getDate() - 2)).toISOString().slice(0, 10);
	document.getElementById(self.fields.auxilio).selectedIndex = 1;
	document.getElementById(self.fields.tipoRiego).selectedIndex = 1;
}

const setTableValues = (self, data = {}) => {
	const source = data.stats ?? data;

	let dotacion = source?.dotacion ?? 0;
	let litrosUsados = source?.litrosUsados ?? 0;
	let litrosRestantes = source?.litrosRestantes ?? 0;
	let litrosExcedentes = source?.litrosExcedentes ?? 0;
	let hectareasRegadas = source.hectareasRestantes || source.Hectareas || 0;

	document.getElementById(self.fields.hectareasRegadas).value = Number.parseFloat(hectareasRegadas).toFixed(4);
	document.getElementById(self.fields.table.dotacion).textContent = Number.parseFloat(dotacion).toFixed(4);
	document.getElementById(self.fields.table.litrosUsados).textContent = Number.parseFloat(litrosUsados).toFixed(4);
	document.getElementById(self.fields.table.litrosRestantes).textContent = Number.parseFloat(litrosRestantes).toFixed(4);
	document.getElementById(self.fields.table.litrosExcedentes).textContent = Number.parseFloat(litrosExcedentes).toFixed(4);
	document.getElementById(self.fields.hidden.temp.dotacion).value = Number.parseFloat(dotacion).toFixed(4);
	document.getElementById(self.fields.hidden.temp.litrosUsados).value = Number.parseFloat(litrosUsados).toFixed(4);
	document.getElementById(self.fields.hidden.temp.litrosRestantes).value = Number.parseFloat(litrosRestantes).toFixed(4);
	document.getElementById(self.fields.hidden.temp.litrosExcedentes).value = Number.parseFloat(litrosExcedentes).toFixed(4);
	document.getElementById(self.fields.hidden.perm.dotacion).value = Number.parseFloat(dotacion).toFixed(4);
	document.getElementById(self.fields.hidden.perm.litrosUsados).value = Number.parseFloat(litrosUsados).toFixed(4);
	document.getElementById(self.fields.hidden.perm.litrosRestantes).value = Number.parseFloat(litrosRestantes).toFixed(4);
	document.getElementById(self.fields.hidden.perm.litrosExcedentes).value = Number.parseFloat(litrosExcedentes).toFixed(4);
}

const handlePopupBehavior = (fields) => {
	const ltsPorSegundoField	= document.getElementById(fields.ltsPorSegundo);

	const dotacionField			= document.getElementById(fields.hidden.temp.dotacion);
	const litrosUsadosField		= document.getElementById(fields.hidden.temp.litrosUsados);

	ltsPorSegundoField.addEventListener('input', function() {
		let litrosUsados = Number.parseFloat(ltsPorSegundoField.value || 0) + Number.parseFloat(litrosUsadosField.value || 0);
		let litrosRestantes = Number.parseFloat(dotacionField.value || 0) - litrosUsados;
		let litrosExcedentes = litrosRestantes < 0 ? Math.abs(litrosRestantes) : 0;
		litrosRestantes = Math.max(litrosRestantes, 0);

		document.getElementById(fields.table.litrosUsados).textContent = Number.parseFloat(litrosUsados).toFixed(4);
		document.getElementById(fields.table.litrosRestantes).textContent = Number.parseFloat(litrosRestantes).toFixed(4);
		document.getElementById(fields.table.litrosExcedentes).textContent = Number.parseFloat(litrosExcedentes).toFixed(4);
		document.getElementById(fields.hidden.perm.litrosUsados).value = Number.parseFloat(litrosUsados).toFixed(4);
		document.getElementById(fields.hidden.perm.litrosRestantes).value = Number.parseFloat(litrosRestantes).toFixed(4);
		document.getElementById(fields.hidden.perm.litrosExcedentes).value = Number.parseFloat(litrosExcedentes).toFixed(4);
	});
}

const handleAutocompleteSetup = (self) => {
	$('#' + self.fields.permisoRiego).autocomplete({
		minLength: 3,
		source: async (request, response) => {
			let url		= URLService(self.routes.PERMISO_RIEGO)

			const seccionRiegoField = self.formManager.getFieldById(self.fields.seccionRiego);
			self.formManager.validateField(seccionRiegoField);

			if (seccionRiegoField.element.dataset.invalid === 'true') return;

			// Call the RequestService object with the url parameter and assign the result to data
			let ajax = new RequestService(url);

			// Use the get method to fetch some data
			let result = await ajax.get({ Busqueda: request.term, Seccion: seccionRiegoField.element.value });

			// Success property gets deleted to avoid interference
			if (result.success) delete result['success']
			else return

			let	permiso = result.map( function (current) {
				const labelParts = [];

				if (current.Folio !== null) labelParts.push(`[${current.Folio}]`)
				if (current.Nombre !== null) labelParts.push(current.Nombre);
				if (current.Cultivo !== null) labelParts.push(`(${current.Cultivo})`);
				if (current.SuperficieAutorizada !== null) labelParts.push(`- ${current.SuperficieAutorizada} ha`)

				const label = labelParts.join(' ');

				return {
					  label: label
					, value: label
					, IdPermisoRiego: current.IdPermisoDeRiego
					, IdPadronUsuario: current.IdPadronUsuario
					, TipoUso: current.TipoUso
					, seccion: current?.IdSeccion ?? ''
				}
			});

			response(permiso);
		},
		select: async (_event, ui) => {
			document.getElementById(self.fields.hidden.idPermiso).value = ui.item.IdPermisoRiego;
			document.getElementById(self.fields.hidden.idPadronUsuario).value = ui.item.IdPadronUsuario;
			$('#' + self.fields.tipoRiego).val(ui.item.TipoUso).prop('selected', true);

			var url	= URLService(self.routes.PERMISO_SELECCIONADO)

			// Call the RequestService object with the url parameter and assign the result to data
			let ajax = new RequestService(url);

			// Use the get method to fetch some data
			let result = await ajax.get({ Permiso: ui.item.IdPermisoRiego, Usuario: ui.item.IdPadronUsuario });

			document.getElementById(self.fields.numeroRiego).value = result.numeroRiego;

			setTableValues(self, result);

			$('#' + self.fields.estado).val(result.estado).prop('selected', true);
			document.getElementById(self.fields.numeroRiego).value = result.numeroRiego;
			document.getElementById(self.fields.estado).value = result.estado;
		},
		change: (_event, ui) => {
			if (ui.item === null) {
				document.getElementById(self.fields.hidden.idPermiso).value = '';
				document.getElementById(self.fields.hidden.idPadronUsuario).value = '';
				document.getElementById(self.fields.numeroRiego).value = '';
				document.getElementById(self.fields.estado).value = '';
				document.getElementById(self.fields.tipoRiego).value = '';

				setTableValues(self);
			}
		}
	});
}

const setFormValidators =  (self) => {
	const seccionRiegoField = self.formManager.getFieldById(self.fields.seccionRiego);
	seccionRiegoField.validators.push(validators.selectValidator);
	const fechaCapturaField = self.formManager.getFieldById(self.fields.fechaCaptura);
	fechaCapturaField.validators.push(validators.dateValidator);
	const cicloField = self.formManager.getFieldById(self.fields.hidden.idCiclo);
	cicloField.validators.push(validators.acquiredIDValidator);
	const idPermisoField = self.formManager.getFieldById(self.fields.hidden.idPermiso);
	idPermisoField.validators.push(validators.acquiredIDValidator);
	const tipoRiegoField = self.formManager.getFieldById(self.fields.tipoRiego);
	tipoRiegoField.validators.push(validators.selectValidator);
	const auxilioField = self.formManager.getFieldById(self.fields.auxilio);
	auxilioField.validators.push(validators.selectValidator);
	const numeroRiegoField = self.formManager.getFieldById(self.fields.numeroRiego);
	numeroRiegoField.validators.push(validators.numberValidator);
	const ltsPorSegundoField = self.formManager.getFieldById(self.fields.ltsPorSegundo);
	ltsPorSegundoField.validators.push(validators.numberValidator);
	const hectareasRegadasField = self.formManager.getFieldById(self.fields.hectareasRegadas);
	hectareasRegadasField.validators.push(validators.numberValidator);
	const estadoField = self.formManager.getFieldById(self.fields.estado);
	estadoField.validators.push(validators.selectValidator);

	self.formManager.fields.forEach( (field) => {
		field.skipValidation = true;
	});
}

const handleTabNavigation = () => {
	$(".modal-body li a").on("click", function() {
		let tab = $(this).attr("href");
		$(".modal-body .tab-content div").each(function() {
			$(this).removeClass("active show");
		});
		$(".modal-body .tab-content " + tab).addClass("active show");
	});
	$('#seccion_riego_nav_tab').on('click', function() {
		$(this).addClass('active');
		$('#seccion_observaciones_nav_tab').removeClass("active");
	});
	$('#seccion_observaciones_nav_tab').on('click', function() {
		$(this).addClass('active');
		$('#seccion_riego_nav_tab').removeClass("active");
	});
}

export class StoreModalManager {

	POPUP			= 'modal_guardar_proriegos'
	STORE_BTN		= 'btn_guardar_proriegos'
	CREATE_BTN		= 'crear_nuevo_proriego'
	TABLE			= 'table_proriegos'
	BALANCE_POPUP	= 'modal-balance-proriegos'

	constructor (CSRF_TOKEN, fields, routes) {
		this.CSRF_TOKEN = CSRF_TOKEN;
		this.fields = fields;
		this.routes = routes;

		const form = document.getElementById('submit-form');
		this.formManager = new FormManager(form);
	}

	initialize () {
		handlePopupBehavior(this.fields);
		this.#handleClickOnCreate();
		this.#handleClickOnEdit();
		this.#handleClickOnBalanceBtn();
		handleTabNavigation();

		this.formManager.initialize();

		setFormValidators(this);
		handleAutocompleteSetup(this);
	}

	#handleClickOnCreate() {
		let createBtn		= document.getElementById(this.CREATE_BTN)

		createBtn?.addEventListener('click', async (event) => {
			event.preventDefault();

			this.formManager.initializeFormForCreation();

			// Call the URLService function with the field and empid parameters
			let url = URLService(this.routes.CREATE_RIEGO, 'GET');

			// Call the RequestService object with the url parameter and assign the result to data
			let ajax = new RequestService(url);

			// Use the get method to fetch some data
			let data = await ajax.get();

			setInitialValues(data, this);
			setTableValues(this, data);

			ModalService(this.POPUP, 'Crear Riego', true);
		});
	}

	#handleClickOnBalanceBtn() {
		let balanceBtn	= document.getElementById(this.BALANCE_BTN)

		balanceBtn?.addEventListener('click', async (event) => {
			event.preventDefault();

			ModalService(this.BALANCE_BTN, 'Saldos', true);
		});
	}

	#handleClickOnEdit () {
		// Get the table element by its ID
		let table = document.getElementById(this.TABLE);
		// Add a click event listener to the table
		table.addEventListener('click', async (event) => {
			// Prevent the default behavior of the event
			event.preventDefault();
			// Get the target element of the event
			let target = event.target;
			// Check if the target element has the class 'edit-record'
			if (target.matches('.edit-record') || target.matches('.edit-record i')) {
				// Get the edit button element
				let editButton = target.closest('.edit-record');
				// Get the data-id attribute of the target element
				let empid = editButton.dataset.id

				// Check if the empid is positive
				if (empid <= 0) return;
				// Call the URLService function with the field and empid parameters
				let url = URLService(this.TABLE, 'GET', empid);

				// Call the RequestService object with the url parameter and assign the result to data
				let ajax = new RequestService(url);

				// Use the get method to fetch some data
				let data = await ajax.get();

				// Check if the data has a success property with a true value
				if (data.success === false)  return;

				this.#setFormValues(data);

				// Shows the modal popup
				ModalService(this.POPUP, 'Modificar Riego', true)
			}

			if (target.matches('.delete-record') || target.matches('.delete-record i')) {
				// Get the delete button element
				let deleteButton = target.closest('.delete-record');
				// Get the data-id attribute of the target element
				let empid = deleteButton.dataset.id

				if (empid <= 0) return;

				var url = URLService(this.TABLE, 'DELETE', empid);

				let ajax = new RequestService(url);

				// Use the delete method
				return ajax.delete('Eliminar Riego');
			}
		});
	}

	setSelectValue = (select, value) => {
		for (const option of select.options) {
			if (option.value == value) {
				option.selected = true;
				break;
			}
		}
	}

	#setFormValues (data) {
		// Get the button element by its ID and set its data-id attribute to the data id property
		this.formManager.initializeFormForUpdate(data.IdCargo);

		// Get the name and email input elements by their IDs and set their values to the data properties
		document.getElementById(this.fields.ciclo).value = data.ciclo.Ciclo;
		document.getElementById(this.fields.hidden.idCiclo).value = data.ciclo.IdCiclo;
		document.getElementById(this.fields.folio).value = data.Folio;
		document.getElementById(this.fields.fechaCaptura).value = new Date(data.Fecha).toISOString().slice(0, 10);
		const seccionRiegoField = document.getElementById(this.fields.seccionRiego);
		this.setSelectValue(seccionRiegoField, data.IdSeccion);
		const auxilioField = document.getElementById(this.fields.auxilio);
		this.setSelectValue(auxilioField, data.Auxilio);
		const tipoRiegoField = document.getElementById(this.fields.tipoRiego);
		this.setSelectValue(tipoRiegoField, data.Tipo);

		const permisoValue = data.permiso_de_riego
		? `[${data.permiso_de_riego.TipoInicial}-${data.permiso_de_riego.Folio}] ${data.usuario_padron?.Nombre || ''}`
		: data.usuario_padron?.Nombre || '';
		document.getElementById(this.fields.permisoRiego).value = permisoValue;

		document.getElementById(this.fields.hidden.idPadronUsuario).value = data.usuario_padron.IdPadronUsuario;
		document.getElementById(this.fields.hidden.idCargo).value = data.IdCargo;
		document.getElementById(this.fields.hidden.idPermiso).value = data.IdPermisoDeRiego;

		document.getElementById(this.fields.numeroRiego).value = data?.NumeroRiego;
		document.getElementById(this.fields.ltsPorSegundo).value = data.LitrosXSegundo;
		document.getElementById(this.fields.estado).value = data.Estado;
		document.getElementById(this.fields.estado).selected = true;
		document.getElementById(this.fields.observaciones).value = data.Observaciones;

		setTableValues(this, data);
	}

}

export class BulkModalManager {
	CREATE_BTN_MASIVO	= 'crear_riegos_masivos'
	POPUP_BULK			= 'modal-bulk-proriegos'
	DATABASE_VERSION	= 1

	constructor (CSRF_TOKEN, fields, routes) {
		this.CSRF_TOKEN = CSRF_TOKEN;
		this.fields = fields
		this.routes = routes;

		const table = document.getElementById('table_bulk_proriegos');
		const submitForm = document.getElementById('submit-form');

		this.tableBulkManager = new TableManager(table);
		this.formManager = new FormManager(submitForm, ['ProRiegos']);

		const indexOptions = {
			IdPermisoDeRiego: { keyPath: 'IdPermisoRiego', unique: false },
			IdPadronUsuario: { keyPath: 'IdPadronUsuario', unique: false }
		};

		this.proRiegosDB = new DatabaseService('ProRiegos', this.DATABASE_VERSION, indexOptions);

		this.initialValues = {};
	}

	initialize () {
		this.formManager.initialize();
		this.proRiegosDB.init();
		setFormValidators(this);
		handleTabNavigation();
		handleAutocompleteSetup(this);
		this.#handleClickOnCreate();
		this.#handlePopupBehavior();
		handlePopupBehavior(this.fields);
	}

	#handleClickOnCreate() {
		let createBtnMasivo	= document.getElementById(this.CREATE_BTN_MASIVO)

		const promptForm = document.createElement('form');
		const divPrompt = document.createElement('div');
		const labelPrompt = document.createElement('label');

		const seccionField = this.formManager.getFieldById(this.fields.seccionRiego).element.cloneNode(true);
		seccionField.id = 'prompt_seccion_riego';

		divPrompt.classList.add('form-group');

		labelPrompt.textContent = 'Selecciona la sección de riego';

		divPrompt.appendChild(labelPrompt);
		divPrompt.appendChild(seccionField);
		promptForm.appendChild(divPrompt);

		let self = this;

		createBtnMasivo?.addEventListener('click', async (event) => {
			event.preventDefault();
			$.confirm({
				title: 'Sección',
				content: promptForm.outerHTML,
				buttons: {
					formSubmit: {
						text: 'Aceptar',
						btnClass: 'btn btn-outline-primary btn-sm',
						action: async function (self_this) {
							var value = this.$content.find('select option').filter(':selected').val();
							if(!value){
								$.alert('Seleccione una opción válida');
								return false;
							}

							self.formManager.initializeFormForCreation();

							// Call the URLService function with the field and empid parameters
							let url = URLService(self.routes.CREATE_RIEGO, 'GET');

							// Call the RequestService object with the url parameter and assign the result to data
							let ajax = new RequestService(url);

							// Use the get method to fetch some data
							let data = await ajax.get({ seccion: value });

							await self.proRiegosDB.clear();

							self.formManager.initializeFormForCreation();

							self.tableBulkManager.cleanTable();

							data.seccionRiego = value;

							setInitialValues(data, self);

							const disabledElements = document.querySelectorAll('.disabled-group');

							disabledElements.forEach(element => { element.disabled = false; });

							const addTableButton = document.getElementById('btn-agregar-riego')
							addTableButton.classList.remove('update')
							addTableButton.textContent = 'Agregar Riego'

							self.initialValues = data;
							ModalService(self.POPUP_BULK, 'Crear Riegos', true)

							let folio = parseInt(data.Folio)

							data.riegos_pendientes.forEach(async (item) => {
								folio++;
								item.Folio = folio;
								const key = await self.proRiegosDB.add(item);
								item.key = key;
								self.tableBulkManager.addTableRow(item, self.proRiegosDB, self.formManager);
							});
						}
					},
					cancel: { text: 'Cancelar',	action: function () { return; }, btnClass: 'btn btn-outline-danger btn-sm' }
				},
				onContentReady: function () {
					// bind to events
					var jc = this;
					this.$content.find('form').on('submit', function (e) {
						// if the user submits the form by pressing enter in the field.
						e.preventDefault();
						jc.$$formSubmit.trigger('click'); // reference the button and click it
					});
				}
			});
		})
	}

	#handlePopupBehavior () {
		const addTableButton = document.getElementById('btn-agregar-riego');

		addTableButton?.addEventListener('click', async (event) => {
			event.preventDefault();

			let formElements = this.formManager.fields.flatMap((field) => field.id.trim()).filter(Boolean);

			this.formManager.setSkipValidationById(formElements, false);

			if (!this.formManager.validateForm()) {
				this.formManager.setSkipValidationById(formElements, true);
				return false;
			}

			if (addTableButton.classList.contains('update')) {
				const disabledElements = document.querySelectorAll('.disabled-group');

				disabledElements.forEach(element => { element.disabled = false; });
			}

			const formData = new FormData(this.formManager.formElement)

			formData.append('Ciclo', this.formManager.formElement.ciclo.value);
			formData.append('Concepto', this.formManager.formElement.concepto.value);
			formData.append('Seccion', this.formManager.formElement.select_seccionriego.value);

			const data	= Object.fromEntries(formData);

			if (!addTableButton.classList.contains('update')) {
				const key = await this.proRiegosDB.add(data);
				data.key = key;
				this.tableBulkManager.addTableRow(data, this.proRiegosDB, this.formManager);
			} else {
				const key = parseInt(addTableButton.dataset.id);
				await this.proRiegosDB.update(key, data);
			}

			const folioField = this.formManager.getFieldById(this.fields.folio);
			const folioValue = parseInt(folioField.element.value) + 1;

			this.formManager.resetForm();

			setInitialValues(this.initialValues, this)
			setTableValues(this)
			addTableButton.classList.remove('update')
			addTableButton.textContent = 'Agregar Riego'
			delete addTableButton.dataset.id
			this.formManager.setValueById(this.fields.folio, folioValue)
			this.formManager.setSkipValidationById(formElements, true)
		});
	}
}

const setFormValuesForUpdate = (data, key, formManager) => {
	// Obtenemos los campos del formulario y los campos del objeto data
	// y los comparamos para asignar los valores correspondientes
	formManager.fields.forEach( (field) => {
		formManager.setValueByName(field.name, data[field.name]);
	});

	// Se deshabilitan todos los campos del formulario
	// que no son parte de la sección de riego
	const disabledElements = document.querySelectorAll('.disabled-group');

	disabledElements.forEach(element => { element.disabled = true; });

	const button = document.getElementById('btn-agregar-riego');
	button.classList.add('update')
	button.dataset.id = key;

	button.textContent = 'Modificar Riego';
}

class TableManager {

	MAX_ROW_COUNT = 5

	/**
	 * Crea una nueva instancia de TableManager.
	 *
	 * @param {HTMLElement} tableElement - El elemento de tabla.
	 *
	 * @property {HTMLElement} table - El elemento de tabla.
	 * @property {HTMLElement} tableHeader - El elemento de encabezado de tabla.
	 * @property {HTMLElement} tableBody - El elemento de cuerpo de tabla.
	 * @property {number} MAX_ROW_COUNT - El número máximo de filas que se pueden mostrar en la tabla.
	 */
	constructor(tableElement) {
		this.table = tableElement;
		this.tableHeader = tableElement.querySelector('thead');
		this.tableBody = tableElement.querySelector('tbody');
	}

	/**
	 * Inicializa la tabla.
	 *
	 * @returns {void}
	 */
	initialize () {

		this.cleanTable();

		// Se configura un observador de mutaciones para recalcular
		// los totales o desaparecerlos al eliminar una fila de la tabla
		const config =  { attributes: false, childList: true, subtree: false };

		const callback = (mutationList, observer) => {
			for (const mutation of mutationList) {
				if (mutation.type === 'childList' && mutation.removedNodes.length > 0) {
					this.setupScrollable();
				}
			}
		};

		const observer = new MutationObserver(callback);
		observer.observe(this.tableBody, config);
	}

	/**
	 * Limpia el cuerpo de la tabla.
	 *
	 * @returns {void}
	 */
	cleanTable () {
		this.tableBody.innerHTML = '';
	}

	/**
	 * Configura la tabla para que sea desplazable si el
	 * número de filas es mayor que el número máximo de filas.
	 * De lo contrario, elimina el desplazamiento.
	 *
	 * @returns {void}
	 */
	setupScrollable() {
		const rowCount = this.tableBody.rows.length;

		rowCount >= this.MAX_ROW_COUNT ? this.#enableScroll() : this.#disableScroll();
	}

	convertDateFormat = (inputDate) => {
		let datePart = inputDate.split("-");
		return `${datePart[2]}/${datePart[1]}/${datePart[0]}`;
	}

	/**
	 * Agrega una fila a la tabla.
	 *
	 * @param {object} data - Los datos de la fila.
	 * @returns {void}
	 */
	addTableRow(data, dbService, formManager) {
		const row = new Row();

		row
			.addCell(data.key, true)
			.addCell(data.NumeroRiego)
			// .addCell(new Date(data.FechaCaptura).toLocaleDateString('es-MX'))
			.addCell(this.convertDateFormat(data.FechaCaptura))
			.addCell(data.Permiso)
			.addActionsCell(dbService, formManager);

		row.appendTo(this.tableBody);

		this.setupScrollable();
	}

	/**
	 * Habilita el desplazamiento para la tabla.
	 * Agrega una clase de CSS para que la tabla sea desplazable.
	 * Agrega un contenedor para la tabla y establece su altura.
	 * Reemplaza la tabla con el contenedor.
	 * Agrega la tabla al contenedor.
	 *
	 * @returns {void}
	 * @private
	 * @memberof TableManager
	 */
	#enableScroll (){
		if (!this.table.classList.contains('scrollable-table')) {
			// const desiredHeight = this.MAX_ROW_COUNT * (this.table.rows[0].offsetHeight + 1);
			const desiredHeight = this.MAX_ROW_COUNT * (34);
			this.table.style.height = desiredHeight + 'px';

			const tableDiv = document.createElement('div');

			this.table.classList.add('scrollable-table');
			tableDiv.classList.add('table-scroll-container');

			tableDiv.style.overflowY = 'scroll';
			tableDiv.style.height = (desiredHeight + 45) + 'px';

			this.table.parentElement.replaceChild(tableDiv, this.table);
			tableDiv.appendChild(this.table);
		}
	}

	/**
	 * Deshabilita el desplazamiento para la tabla.
	 * Elimina la clase de CSS para que la tabla no sea desplazable.
	 * Reemplaza el contenedor de la tabla con la tabla.
	 * Elimina la altura de la tabla.
	 * Elimina el contenedor de la tabla.
	 *
	 * @returns {void}
	 * @private
	 * @memberof TableManager
	 */
	#disableScroll() {
		if (this.table.classList.contains('scrollable-table')) {
			const tableDiv = this.table.parentElement;
			tableDiv.parentElement.replaceChild(this.table, tableDiv);
			this.table.style.height = '';
			this.table.classList.remove('scrollable-table');
		}
	}
}

class Row {
	/**
	 * Crea una nueva instancia de Row.
	 *
	 * @property {HTMLElement} rowElement - El elemento de fila.
	 * @property {HTMLElement[]} cells - Las celdas de la fila.
	 */
	constructor() {
	  this.rowElement = document.createElement('tr');
	  this.cells = [];
	}

	/**
	 * Agrega una celda a la fila.
	 *
	 * @param {string} content - El contenido de la celda.
	 * @param {boolean} isHidden - Indica si la celda debe estar oculta.
	 * @returns {Row} - La instancia actual de la fila.
	 */
	addCell(content, isHidden = false) {
		const cell = document.createElement('td');
		cell.textContent = content;

		if (isHidden) {
			cell.classList.add('hidden');
		}

		this.cells.push(cell);

		return this;
	}

	/**
	 * Crea un botón de eliminar.
	 *
	 * @private
	 * @memberof Row
	 * @method
	 * @name #createDeleteButton
	 * @instance
	 *
	 * @returns {HTMLElement} - El botón de eliminar.
	 */
	#createDeleteButton() {
		const deleteButton = document.createElement('button');
		deleteButton.type = 'button';
		deleteButton.classList.add('btn', 'btn-outline-danger', 'btn-sm');

		const deleteIcon = document.createElement('i');
		deleteIcon.classList.add('far', 'fa-trash-can');

		deleteButton.appendChild(deleteIcon);

		return deleteButton;
	}

	/**
	 * Crea un botón de editar.
	 *
	 * @private
	 * @memberof Row
	 * @method
	 * @name #createEditButton
	 * @instance
	 *
	 * @returns {HTMLElement} - El botón de editar.
	 */
	#createEditButton() {
		const editButton = document.createElement('button');
		editButton.type = 'button';
		editButton.classList.add('btn', 'btn-outline-primary', 'btn-sm');

		const editIcon = document.createElement('i');
		editIcon.classList.add('far', 'fa-edit');

		editButton.appendChild(editIcon);

		return editButton;
	}

	/**
	 * Maneja el evento de clic en el botón de eliminar.
	 *
	 * @param {DatabaseService} dbService - El servicio de base de datos.
	 * @param {HTMLElement} deleteButton - El botón de eliminar.
	 *
	 * @returns {void} @private @memberof Row @async @inner @method
	 * @name #handleDeleteClick
	 * @instance
	 */
	async #handleDeleteClick(dbService, deleteButton) {
		const row = deleteButton.closest('tr');
		const key = row.cells[0].textContent;

		await dbService.delete(parseInt(key));

		const addTableButton = document.getElementById('btn-agregar-riego');
		if (addTableButton.classList.contains('update')) {
			$.confirm({
				title: 'ERROR',
				type: 'red',
				content: 'No puede eliminarse un registro que está siendo modificado.',
				autoClose: 'Ok|4000',
				buttons: { Ok: { text: 'Ok'	} }
			});
			return;
		}

		if (row) row.remove();
	}

	/**
	 * Maneja el evento de clic en el botón de editar.
	 *
	 * @param {DatabaseService} dbService - El servicio de base de datos.
	 * @param {HTMLElement} editButton - El botón de editar.
	 * @param {FormManager} formManager - El administrador de formularios.
	 *
	 * @returns {void} @private @memberof Row @async @inner @method
	 * @name #handleEditClick
	 * @instance
	 */
	async #handleEditClick(dbService, editButton, formManager) {
		const row = editButton.closest('tr');
		const key = row.cells[0].textContent;

		const data = await dbService.getById(parseInt(key));

		setFormValuesForUpdate(data, key, formManager);
	}

	/**
	 * Agrega una celda de eliminación a la fila.
	 *
	 * @param {DatabaseService} dbService - El servicio de base de datos.
	 * @param {FormManager} formManager - El administrador de formularios.
	 *
	 * @returns {Row} - La instancia actual de la fila.
	 */
	addActionsCell(dbService, formManager) {
		const deleteCell = document.createElement('td');

		const deleteButton = this.#createDeleteButton();

		deleteButton.addEventListener('click', async () => {
			this.#handleDeleteClick(dbService, deleteButton);
		});

		const editButton = this.#createEditButton();

		editButton.addEventListener('click', async () => {
			this.#handleEditClick(dbService, editButton, formManager);
		});

		deleteCell.appendChild(editButton);
		deleteCell.appendChild(deleteButton);

		this.cells.push(deleteCell);

		return this;
	}

	/**
	 * Agrega la fila al elemento padre.
	 *
	 * @param {HTMLElement} parentElement - El elemento padre al que se agregará la fila.
	 * @returns {Row} - La instancia actual de la fila.
	 */
	appendTo(parentElement) {
		for (const cell of this.cells) {
			this.rowElement.appendChild(cell);
		}

		parentElement.appendChild(this.rowElement);
	}
}
