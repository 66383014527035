import { RequestService } from "./services";

/**
 * Representa un objeto FormManager.
 * @class
 * @classdesc	Esta clase se utiliza para la manipulación y recolección de información
 * 				dentro del elemento <form>.
 */
export default class FormManager {
	/**
	 * Crea un nuevo objeto FormManager.
	 * @param {Element} formElement - Representa el <form> a manipular.
	 * @param {Array} tableElements - Tablas dentro del <form> que forman parte de los datos a manipular.
	 */
	constructor(formElement, tableElements = []) {
		this.formElement = formElement;
		this.fields = [];
		this.tableElements = tableElements;
		this.datatablesObject = null;
	}

	/**
	 * Inicializa el objeto FormManager.
	 *
	 * @returns {void}
	 */
	initialize() {
		this.collectFields();
		this.setupValidation();
		this.setupSubmitHandler();
	}

	/**
	 * Inicializa el formulario y las propiedades de los campos
	 * para crear un nuevo registro.
	 *
	 * @returns {void}
	 */
	collectFields() {
		const inputElements = this.formElement.querySelectorAll('input, select, textarea, button, input[type="checkbox"], input[type="radio"]');

		for (const input of inputElements) {
			const field = {
				element: input,
				name: input.name,
				id: input.id,
				validators: [],
				skipValidation: false,
			};

			this.fields.push(field);
		}
	}

	/**
	 * Inicializa los elementos del formulario para que
	 * se inicie la validación del campo al momento de perder el foco.
	 *
	 * @returns {void}
	 */
	setupValidation() {
		for (const field of this.fields) {
			field.element.addEventListener('blur', () => {
				if (!field.skipValidation) {
					this.validateField(field);
				}
			});
		}
	}

	/**
	 * Inicializa los eventos del evento submit del formulario.
	 *
	 * @returns {void}
	 * @listens submit
	 * @listens keydown
	 * @fires submit
	 * @fires keydown
	 */
	setupSubmitHandler() {
		// Evita que se envíe el formulario al presionar Enter
		// en cualquier campo que no sea el botón de Enviar
		// en su lugar, se valida el formulario
		this.formElement.addEventListener('keydown', (event) => {
			if (event.key === 'Enter' && event.target.type !== 'submit') {
				event.preventDefault();
				this.validateForm()
			}
		});

		// Envía el formulario al presionar el botón de Enviar
		this.formElement.addEventListener('submit', (event) => {
			event.preventDefault();

			if (this.validateForm()) {
				this.submitForm();
			}
		});
	}

	/**
	 * Establece ACTION, METHOD y ID del formulario para crear
	 * un nuevo registro.
	 *
	 * @returns {void}
	 */
	initializeFormForCreation() {
		this.formElement.action		= this.formElement.dataset.createRoute;
		this.formElement.dataset.id	= '';
		this.formElement.method		= 'POST';

		this.resetForm();
	}

	/**
	 * Establece ACTION, METHOD y ID del formulario para actualizar
	 * un registro existente.
	 *
	 * @returns {void}
	 */
	initializeFormForUpdate(id) {
		const updateRoute = this.formElement.dataset.updateRoute;
		const updatedRoute = new URL(updateRoute, window.location.origin);
		updatedRoute.pathname = updatedRoute.pathname.replace(/\/\d+$/, `/${id}`);

		this.formElement.action		= updatedRoute.toString();
		this.formElement.dataset.id	= id;
		this.formElement.method		= 'PUT';

		this.resetForm();
	}

	/**
	 * Valida un campo del formulario.
	 *
	 * @param {Object} field - Representa el campo a validar.
	 * @returns {void}
	 * @fires showErrorMessage
	 */
	validateField(field) {
		for (const validator of field.validators) {
			if (!validator.validate(field.element.value)) {
				this.showErrorMessage(field, validator.message);
				return;
			}
		}

		this.hideErrorMessage(field);
	}

	/**
	 * Valida el formulario completo
	 *
	 * @returns {boolean} - Devuelve true si el formulario es válido, de lo contrario, devuelve false.
	 * @fires validateField
	 */
	validateForm() {
		let isFormValid = true;

		for (const field of this.fields) {
			if (!field.skipValidation) {
				this.validateField(field);

				if (field.element.dataset.invalid === 'true') {
					isFormValid = false;
				}
			}
		}

		return isFormValid;
	}

	/**
	 * Establece si un campo debe ser validado o no.
	 *
	 * @param {String/Array} ids
	 * @param {boolean} skipValidation
	 */
	setSkipValidationById(ids, skipValidation) {
		if (typeof ids === 'string') {
			ids = [ids]; // Convert single ID to an array
		}

		for (const id of ids) {
			const field = this.getFieldById(id);

			if (field) {
				field.skipValidation = skipValidation;
			}
		}
	}

	/**
	 * Muestra el mensaje de error en el campo
	 *
	 * @param {Element} field - Representa el campo a validar.
	 * @param {String} errorMessage - Mensaje de error a mostrar.
	 * @returns {void}
	 */
	showErrorMessage(field, errorMessage) {
		const errorElement = document.getElementById(`${field.id}-error`);

		if (errorElement) {
			errorElement.textContent = errorMessage;
			errorElement.style.display = 'block';
		}

		field.element.dataset.invalid = 'true';
	}

	/**
	 * Oculta el mensaje de error en el campo
	 *
	 * @param {Element} field - Representa el campo a validar.
	 * @returns {void}
	 */
	hideErrorMessage(field) {
		const errorElement = document.getElementById(`${field.id}-error`);

		if (errorElement) {
			errorElement.textContent = '';
			errorElement.style.display = 'none';
		}

		field.element.dataset.invalid = 'false';
	}

	getFieldByName(fieldName) {
		return this.fields.find((field) => field.name === fieldName);
	}

	getFieldById(id) {
		return this.fields.find((field) => field.id === id);
	}

	getSubmitButton() {
		return this.fields.find((field) => field.element.type === 'submit');
	}

	setValueById(id, value) {
		const field = this.getFieldById(id);

		if (field) {
			switch (field.element.type) {
				case 'checkbox':
				case 'radio':
					field.element.checked = value || false;
					break;
				case 'number':
					const formattedValue = parseFloat(value).toFixed(3);
					field.element.value = isNaN(formattedValue) ? '' : formattedValue;
					break;
				case 'select-one':
				case 'select-multiple':
					for (const option of field.element.options) {
						if (option.value == value) {
							option.selected = true;
							break;
						}
					}
					break;
				default:
					field.element.value = value || '';
					break;
			}
		}
	}

	setValueByName(name, value) {
		const field = this.getFieldByName(name);

		if (field) {
			switch (field.element.type) {
				case 'checkbox':
				case 'radio':
					field.element.checked = value || false;
					break;
				case 'number':
					const formattedValue = parseFloat(value).toFixed(3);
					field.element.value = isNaN(formattedValue) ? '' : formattedValue;
					break;
				case 'select-one':
				case 'select-multiple':
					for (const option of field.element.options) {
						if (option.value == value) {
							option.selected = true;
							break;
						}
					}
					break;
				default:
					field.element.value = value || '';
					break;
			}
		}
	}

	clearSelectOptions(selectElement) {
		const select = document.getElementById(selectElement);
		const options = [...select.options];

		options.slice(1).forEach((option) => {
			select.removeChild(option);
		});
	}

	getSelectedOptionById(id) {
		const selectField = document.getElementById(id);

		const selectedOption = selectField.options[selectField.selectedIndex];

		return {
		  value: selectedOption.value,
		  text: selectedOption.text,
		};
	}


	resetFields(fieldNames) {
		for (const field of this.fields) {
			if (fieldNames.includes(field.id)) {
				field.element.value = '';
				this.hideErrorMessage(field);
			}
		}
	}

	resetForm() {
		// Se limpian todos los campos
		this.formElement.reset();
		// Se ocultan todos los mensajes de error
		const inputElements = document.querySelectorAll('div.alert, div.alert-danger');

		for (const input of inputElements) {
			input.textContent = '';
			input.style.display = 'none';
		}
	}

	getTableData(tableElementID) {
		const table = document.getElementById(tableElementID);

		if (!table) return null;

		const columnHeaders = Array.from(table.rows[0].cells).map(cell => cell.textContent.trim());

		const tableData = [];

		for (let i = 1; i < table.rows.length; i++) {
			const rowData = {};
			const row = table.rows[i];

			if (row.closest('tfoot')) continue;

			for (let j = 0; j < (columnHeaders.length - 1); j++) {
				rowData[columnHeaders[j]] = row.cells[j].textContent.trim();
			}

			tableData.push(rowData);
		}

		return tableData;
	}

	async getAllRecordsFromTable(tableName) {
		return new Promise((resolve, reject) => {
			const dbRequest = indexedDB.open('AgrosistemasDB', 1);

			dbRequest.onerror = () => {
				reject(Error('Failed to open database.'));
			};

			dbRequest.onsuccess = () => {
				const db = dbRequest.result;
				const transaction = db.transaction(tableName, 'readonly');
				const objectStore = transaction.objectStore(tableName);
				const request = objectStore.getAll();

				request.onerror = () => {
					reject(Error('Failed to retrieve records from table.'));
				};

				request.onsuccess = () => {
					resolve(request.result);
				};
			};
		});
	}

	async submitWithDatabase(formData) {
		const promises = [];

		this.tableElements.forEach((element) => {
			const promise = this.getAllRecordsFromTable(element)
				.then((records) => {
					formData.append(element, JSON.stringify(records));
				})
				.catch((error) => {
					console.error(error);
				});

			promises.push(promise);
		});

		let url = this.formElement.action;
		const service = new RequestService(url);

		return Promise.all(promises)
			.then(() => {
				if (this.formElement.method === 'post') {
					return service.post(formData);
				} else {
					return service.put(formData);
				}
			})
			.catch((error) => {
				console.error(error);
			});
	}

	async submitForm() {
		const formData = new FormData(this.formElement);

		this.tableElements.forEach((element) => {
			if (this.datatablesObject !== null) {
				const tableData = this.datatablesObject.data().toArray();

				formData.append(element, JSON.stringify(tableData));
				return;
			} else {
				const tableData = this.getTableData(element);

				formData.append(element, JSON.stringify(tableData));
			}
		});

		let currentURL = new URL(window.location.href);
		let pathname = currentURL.pathname.replace(/\/+$/, '');
		pathname = pathname.split('/').pop();
		let url = this.formElement.action;
		const service = new RequestService(url);

		if (pathname === 'proriegos'){
			await this.submitWithDatabase(formData)
		} else {
			if (this.formElement.method === 'post') {
				return service.post(formData);
			} else {
				return service.put(formData);
			}
		}
	}

	setDatatableObject(datatableObject) {
		this.datatablesObject = datatableObject;
	}
}
